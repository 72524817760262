import React, { useEffect, useState } from 'react';
import { getAssetAppleLink } from '../apiclients/AssetLinkApiClient';

function AssetApplelink() {
  const [assetLinks, setAssetLinks] = useState({
    "applinks": {
        "details": [
             {
               "appIDs": [ "3DX9GUL6MV.com.playall.playallrn" ]
             }
         ]
     }
  });

  // useEffect(() => {
  //   getAssetAppleLink().then((response) => {
  //     console.log('AppleAsset links:', response.data);
  //       setAssetLinks(response.data);
  //     })
  //     .catch(error => {
  //       console.error('Error fetching apple assetlinks.json:', error);
  //     });
  // }, []);

  return (
    <div>
      {/* Render the asset links JSON here */}
      {assetLinks && (
        <pre>{JSON.stringify(assetLinks, null, 2)}</pre>
      )}
    </div>
  );
}

export default AssetApplelink;