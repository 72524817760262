import { tokenAxiosInstance, setTokenAuthHeader } from "./ApiClient";
import { API_URLS } from "./url";
import { setToken, getToken } from "../utils/localStorageFunction";

export const login = (username, password) => {
  const params = new URLSearchParams();
  params.append("username", username);
  params.append("password", password);
  return tokenAxiosInstance.post(API_URLS.LOGIN, params).then((response) => {
    const data = response.data;
    const token = data.token;
    setTokenAuthHeader(token);
    setToken(token);
    return data.userDetail;
  });
};

export const getLoggedUserDetails = () => {
  const token = getToken();
  if (!token) {
    return Promise.reject("Unauthorized user");
  }
  setTokenAuthHeader(token);
  return tokenAxiosInstance.get(API_URLS.LOGGED_USER).then((response) => {
    const data = response.data;
    return data.userDetail;
  });
};
