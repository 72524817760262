import React, { useState, useEffect } from "react";
import { Button } from "semantic-ui-react";

import "../style.scss";
import CustomModal from "../../generic/CustomModal";
import BookinInfo from "../BookingInfo";
import ExtendBooking from "./ExtendBooking";
import EarlyBooking from "./EarlyBooking";
import RentOrSaleProduct from "./RentOrSaleProduct";
import RentSaleProducts from "./RentSaleProducts";
import { bookedBookingOptions } from "./constants";
import { ROLES } from "../../../utils/constants";
import CancelBooking from "./CancelBooking";
import RescheduleBooking from "./RescheduleBooking";
import moment from "moment";
import { getKeyValue } from "../../../utils/helperFunctions";
import { useContext } from "react";
import { AppContext } from "../../../AppContext";
import PayNow from "./PayNow";
import ChangePaymentMode from "./ChangePaymentMode";
import ReleaseSlot from "./ReleaseSlot";
import SaleCafeProducts from "./SaleCafeProducts";
import ComplimentaryProducts from "./ComplimentaryProducts";

const Footer = ({ setOpen, type, setType, disabled, onConfirm }) => {
  return (
    <>
      {type !== "request" && (
        <Button onClick={() => setType("request")}>Go Back</Button>
      )}
      <Button onClick={() => setOpen(false)}>Close</Button>
    </>
  );
};

const Body = ({
  booking,
  facility,
  setType,
  disabled,
  onConfirm,
  showContent,
}) => {
  const currentHour = moment().hour();
  const context = useContext(AppContext);
  const user = context.user;
  const timings = getKeyValue(context, "selectedSportDetails.detail.timings");
  const complimentaryAllowed = getKeyValue(context, "selectedSportDetails.detail.allowComplimentaryProduct");
  const currentSlotTime = timings
    ? timings.find((t) => t.timeId === booking.slotTiming)
    : undefined;
  const end = currentSlotTime.interval?.end.split(":")[0];
  const start = currentSlotTime.interval?.start.split(":")[0];
  const selectedArena = context.selectedArena;
  const arenaId = selectedArena.arenaId;
  const currentArena = context.arenaData;
  let modules = {};
  if (currentArena[arenaId] !== undefined) {
    modules = currentArena[arenaId].modules;
  }

  const isEarlyBookingAllowed=(
    start-1 === currentHour
    && moment(booking.bookingDate).format('DD-MM-YYYY') === moment().format('DD-MM-YYYY')
    && !(booking.extendedFlag) && !(booking.bookingStatus === "blocked")
  )
    || (
      user.role === ROLES.ADMIN && !(booking.extendedFlag)
      && user.role !== ROLES.USER && !(booking.bookingStatus === "blocked")
    )
    ;

  const isExtendAllowed = (
    +end === currentHour
    && moment(booking.bookingDate).format('DD-MM-YYYY') === moment().format('DD-MM-YYYY')
    && !(booking.extendedFlag) && !(booking.bookingStatus === "blocked")
  )
    || (
      user.role === ROLES.ADMIN && !(booking.extendedFlag)
      && user.role !== ROLES.USER && !(booking.bookingStatus === "blocked")
    );

  const updatedOptions = bookedBookingOptions.filter((x) => {
    if (moment(booking.bookingDate) >= moment().subtract(1, "days") || user.role === ROLES.ADMIN || user.role === ROLES.USER) {
      if ((x.type === "sale" && (modules.productsSaleAndRental === false || user.role === ROLES.USER || booking.bookingStatus === "blocked"))) {
        return false;
      }
      if ((x.type === "complimentary" && (modules.productsSaleAndRental === false || complimentaryAllowed !== true || booking.isComplimentary || user.role === ROLES.USER))) {
        return false
      }
      if ((x.type === "cafe" && (modules.cafeManagement === false || user.role === ROLES.USER || booking.bookingStatus === "blocked"))) {
        return false;
      }
      if (x.type === "releaseSlot" && !(user.role === ROLES.ADMIN || user.role === ROLES.REGIONAL_MANAGER)) {
        return false;
      }
      if (x.type === "extend") {
        return isExtendAllowed;
      }
      // if (x.type === "early") {
      //   return isEarlyBookingAllowed;
      // }
      if (x.type === "paynow") {
        return user.role !== ROLES.USER ?
          (booking.bookingStatus === "extended" || booking.bookingStatus === "booked") && booking.amountDue > 0
          : false;
      }
      if (
        (x.type === "reschedule" || x.type === "cancel") &&
        (booking.bookingStatus === "bulk" ||
          booking.bookingStatus === "blocked" ||
          booking.bookingStatus === "individual" ||
          booking.bookingStatus === "group" ||
          booking.bookingStatus === "extended")
      ) {
        return false;
      }
      if (
        x.type === "changepaymode" &&
        (+start > currentHour || currentHour > +end + 3 || user.role === ROLES.USER || booking.bookingStatus === "blocked")
      ) {
        return false;
      }

      if (x.type === "changepaymode" &&
        booking.thirdParty === true
      ) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  });

  return (
    <>
      <BookinInfo
        booking={booking}
        facility={facility}
        openAccordion={showContent}
      />
      <div
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          display: "flex",
          justifyContent: "flex-start",
          marginTop: "20px",
        }}
      >
        {updatedOptions.map((x, i) => {
          return (
            <div
              key={i}
              className={"booked-button"}
              onClick={() => setType(x.type)}
            >
              <div className={"booked-button-title"}>{x.name}</div>
              <div className={"booked-button-description"}>{x.description}</div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const getTitle = (type) => {
  if (type === "request") {
    return "Manage Booking";
  } else if (type === "reschedule") {
    return "Reschedule";
  } else if (type === "cancel") {
    return "Cancel Booking";
  } else if (type === "extend") {
    return "Extend Booking";
  }  
  // else if (type === "early") {
  //   return "Early Booking";
  // }
  else if (type === "sale") {
    return "Rent/Purchase product";
  } else if (type === "cafe") {
    return "Cafe Product";
  } else if (type === "paynow") {
    return "Pay Dues";
  } else if (type === "changepaymode") {
    return "Change Payment Mode";
  } else if (type === "releaseSlot") {
    return "Release Slot";
  } else if (type === "complimentary") {
    return "Complimentary Product";
  }
};

const getBody = (type) => {
  if (type === "request") {
    return Body;
  } else if (type === "reschedule") {
    return RescheduleBooking;
  } else if (type === "cancel") {
    return CancelBooking;
  } else if (type === "sale") {
    return RentSaleProducts;
  } else if (type === "cafe") {
    return SaleCafeProducts;
  } else if (type === "extend") {
    return ExtendBooking;
  }
  // else if (type === "early") {
  //   return EarlyBooking;
  // } 
   else if (type === "paynow") {
    return PayNow;
  } else if (type === "changepaymode") {
    return ChangePaymentMode;
  } else if (type === "releaseSlot") {
    return ReleaseSlot;
  } else if (type === "complimentary") {
    return ComplimentaryProducts;
  } else {
    return Body;
  }
};
const BookedBooking = ({
  facility,
  setSelectedSlot,
  selectedSlot,
  timings,
  selectedDate,
  reload,
  setMessage,
  isLoading
}) => {
  const [type, setType] = useState("request");
  const title = getTitle(type);
  const ReqBody = getBody(type);
  return (
    <CustomModal
      header={title}
      content={
        <ReqBody
          booking={selectedSlot}
          facility={facility}
          setType={setType}
          setOpen={setSelectedSlot}
          allowRent={type === "rent"}
          showContent={type === "cancel"}
          reload={reload}
          setMessage={setMessage}
          isLoading={isLoading}
        />
      }
      comp={type !== "request"}
      footer={
        type === "request" ? (
          <Footer setOpen={setSelectedSlot} type={type} setType={setType} />
        ) : null
      }
      setOpen={setSelectedSlot}
      open={!!selectedSlot}
    />
  );
};
export default BookedBooking;
