import React, { useEffect, useContext, useState, useReducer } from "react";
import { Button, Icon, Modal, Table } from "semantic-ui-react";

import "../dashboard/style.scss";
import { AppContext } from "../../AppContext";
import { editReceiptNumber, loadBookingTransactions, loadAccountsTransactions } from "../../apiclients/ReportsApiClient";
import { convertFirstoreDate, convertFirstoreDateForExcel, getKeyValue, getSubtypeLabel, getBookingStatusLabel, capitalizeFirstLetter, } from "../../utils/helperFunctions";
import CustomModal from "../generic/CustomModal";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { colors } from "@material-ui/core";
import { filterReducer, FILTERS_ACTION_TYPE, initialFilterState, } from "./bookingReportReducer";
import GenericLoader from "../generic/GenericLoader";
import TransactionDetails from "./TransactionDetails";
import ExportToExcel from "../generic/ExportToExcel";
import { BOOKING_SOURCES, ROLES, TRANSACTION_SUBTYPES, TRANSACTION_TYPE, BOOKING_STATUS, HUDLE_BOOKING_SOURCES, THIRD_PARTY_BOOKING_SOURCE, PAYMENT_STATUS } from "../../utils/constants";
import EditReceiptNumber from "./EditReceiptNumber";
import Toast from "../generic/Toast";
import moment from "moment";
import ChangePaymentMode from "./ChangePaymentMode";


const paymentOptions = [
  { label: "Paytm", value: "paytm" },
  { label: "Credit/Debit Card", value: "card" },
  { label: "Cash", value: "cash" },
  { label: "Other", value: "other" },
  { label: "Bank Transfer", value: "bankTransfer" },
  { label: "Online", value: "Online" },
];

const createStringForProuducts = (products) => {
  let text = "";
  for (var i = 0; i < products.length; i++) {
    if (products[i].isRental) {
      text = text + "For Rental - " + products[i].name;
    } else {
      text = text + 'For Sale - ' + products[i].name;
    }
    text = text + ", Qty - " + products[i].quantity + ", Price - Rs. " + products[i].price + "/- , Discount - " + products[i].discount + " %"
    if (i !== products.length - 1) { // for the list loop
      text = text + ' | '
    }
  }
  return text;
}

const createAcademySummary = (academyTrans) => {
  let text = "";
  text = academyTrans.startDate && academyTrans.endDate ?
    'Parents Name - ' + academyTrans.parentName + ', ' +
    'From - ' + new Date(academyTrans.startDate.seconds * 1000).toLocaleDateString() +
    ', To - ' + new Date(academyTrans.endDate.seconds * 1000).toLocaleDateString() +
    ', Registration Fees - Rs. ' + academyTrans.registrationFees + '/-' : '';
  return text;
}

const createStringForPayments = (payments) => {
  let text = "";
  for (var i = 0; i < payments.length; i++) {
    text = text + payments[i].mode + " - Rs. " + payments[i].amount + "/- ";
    if (payments[i].remark !== "") {
      text = text + ",Remarks - " + payments[i].remark;
    }
    if (i !== payments.length - 1) { // for the list loop
      text = text + ' | '
    }
  }
  return text;
}





const FilterFooter = ({ setShowFilter, onApply }) => {
  return (
    <Modal.Actions>
      <Button className={"ui button booked-footer"} onClick={onApply}>
        Apply
      </Button>
      <Button onClick={() => setShowFilter(false)}>Cancel</Button>
    </Modal.Actions>
  );
};

const FilterContent = ({ onApply, setShowFilter, selectedFilters, role }) => {
  const [filters, dispatch] = useReducer(filterReducer, {
    ...initialFilterState,
    ...selectedFilters,
  });
  const { startDate, endDate, paymentMode, bookingModes } = filters;

  const onReset = () => {
    dispatch({ type: FILTERS_ACTION_TYPE.RESET });
  };
  const onFilterApplyClicked = () => {
    onApply(filters);
  };
  const updateSelectedModes = (mode) => () => {
    dispatch({
      type: FILTERS_ACTION_TYPE.ADD_PAYMENT_MODES,
      payload: paymentMode.value === mode.value ? {} : mode,
    });
  };



  return (
    <>
      <Modal.Header>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Filters</span>
          <span
            style={{
              textTransform: "uppercase",
              fontSize: "14px",
              color: colors.orange[500],
            }}
            onClick={onReset}
          >
            Clear all
          </span>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div>
          <div
            style={{
              paddingTop: "20px",
              paddingBottom: "10px",
              fontWeight: "bold",
            }}
          >
            Transaction Date
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <div style={{ fontWeight: "bold" }}>Start Date</div>
            <KeyboardDatePicker
              autoOk
              variant='inline'
              value={startDate}
              placeholder='Select Date...'
              onChange={(date) =>
                dispatch({
                  type: FILTERS_ACTION_TYPE.ADD_START_DATE,
                  payload: date,
                })
              }
              format='DD MMM yyyy'
              style={{ padding: "10px" }}
              minDate={!(role === ROLES.ADMIN || role === ROLES.ACCOUNTANT || role === ROLES.AUDITOR) ? moment().subtract(7, 'days') : undefined}
              maxDate={moment()}
            />
            <div style={{ fontWeight: "bold" }}>End Date</div>
            <KeyboardDatePicker
              autoOk
              variant='inline'
              value={endDate}
              placeholder='Select Date...'
              onChange={(date) =>
                dispatch({
                  type: FILTERS_ACTION_TYPE.ADD_END_DATE,
                  payload: date,
                })
              }
              format='DD MMM yyyy'
              style={{ padding: "10px" }}
              minDate={!(role === ROLES.ADMIN || role === ROLES.ACCOUNTANT || role === ROLES.AUDITOR) ? moment().subtract(7, 'days') : undefined}
              maxDate={moment()}
            />
          </div>
          <div
            style={{
              paddingTop: "20px",
              paddingBottom: "10px",
              fontWeight: "bold",
            }}
          >
            Payment Mode
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            {paymentOptions.map((x) => {
              const isActive = paymentMode && paymentMode.value === x.value;
              return (
                <Button
                  onClick={updateSelectedModes(x)}
                  style={{
                    marginRight: "10px",
                    backgroundColor: isActive
                      ? colors.orange[700]
                      : colors.grey[50],
                    color: isActive ? colors.blue[50] : colors.black,
                  }}
                >
                  {x.label}
                </Button>
              );
            })}
          </div>

        </div>
      </Modal.Content>
      <FilterFooter
        onApply={onFilterApplyClicked}
        setShowFilter={setShowFilter}
      />
    </>
  );
};

const BookingFilters = ({
  setShowFilter,
  showFilter,
  selectedFilters,
  onApply,
  role
}) => {
  return (
    <CustomModal
      comp={true}
      content={
        <FilterContent
          onApply={onApply}
          selectedFilters={selectedFilters}
          setShowFilter={setShowFilter}
          role={role}
        />
      }
      setOpen={setShowFilter}
      open={showFilter}
    />
  );
};

const NewAccountingReport = () => {
  const { selectedFacility, selectedArena, user, arenaData } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [tallyData, setTallyData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState(initialFilterState);
  const [showFilter, setShowFilter] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [showEditReceiptModal, setShowEditReceiptModal] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messageContent, setMessageContent] = useState({});
  const [changeAmount, setChangeAmount] = useState(0);
  const [showChangePaymentModal, setShowChangePaymentModal] = useState(false);
  const [selectedTransactionId, setSelectedTransactionId] = useState(0);

  const arenaAccountsName = arenaData[selectedArena.arenaId].accountsArenaName;
  useEffect(() => {
    if (!loadData) {
      setIsLoading(true);
    }
  }, [selectedArena]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (reload) {
      loadData().then(() => {
        setReload(false);
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);
        }, 2000);
      });
    }
  }, [reload]);// eslint-disable-line react-hooks/exhaustive-deps

  const loadData = () => {
    const params = {
      startDate: selectedFilters.startDate,
      endDate: selectedFilters.endDate,
      type: "newAccounting",
    };
    if (getKeyValue(selectedFilters, "paymentMode.value")) {
      params.paymentMode = selectedFilters.paymentMode.value;
    }

    return loadAccountsTransactions(
      selectedFacility,
      selectedArena.arenaId,
      params
    ).then((res) => {
      setData(res.data.data);
      setTallyData(res.data.tallyData);
      setIsLoading(false);
    });
  };

  const tableHeader = [
    { label: "Receipt Number", value: "receiptNumber" },
    { label: "Booking Type", value: "type" },
    { label: "Sub Category", value: (col) => getSubtypeLabel(col.subtype) },
    { label: "Arena", value: "arenaName" },
    { label: "Sport", value: (col) => (col.alternativeSport ? col.alternativeSport.text : col.sport ? col.sport.sportName : "") },
    {
      label: "Transaction Date",
      value: (col) => convertFirstoreDateForExcel(col.createdDate),
    },
    { label: "Customer Name", value: "userName" },
    {
      label: "Customer Number",
      value: (col) => (col.userMobileNumber && !(user.role === ROLES.AUDITOR) ? col.userMobileNumber : ""),
    },
    { label: "Total Amount (in Rs.)", value: "totalAmount" },
    { label: "Discount (in %)", value: "discount" },
    { label: "Amount Paid (in Rs.)", value: "amountPaid" },
    { label: "Amount Due (in Rs.)", value: "amountDue" },
    {
      label: "UPI (in Rs.)",
      value: (col) =>
        col.payment
          ? col.payment
            .filter((x) => x.mode.toLowerCase() === "upi")
            .map((x) => x.amount)
            .reduce((x, sum) => Number(sum) + Number(x), 0)
          : "",
    },
    {
      label: "Card Payment (in Rs.)",
      value: (col) =>
        col.payment
          ? col.payment
            .filter((x) => x.mode.toLowerCase() === "card")
            .map((x) => x.amount)
            .reduce((x, sum) => Number(sum) + Number(x), 0)
          : "",
    },


    {
      label: "Cash (in Rs.)",
      value: (col) =>
        col.payment
          ? col.payment
            .filter((x) => x.mode.toLowerCase() === "cash")
            .map((x) => x.amount)
            .reduce((x, sum) => Number(sum) + Number(x), 0)
          : "",
    },
    {
      label: "Bank Transfer (in Rs.)",
      value: (col) =>
        col.payment
          ? col.payment
            .filter((x) => x.mode.toLowerCase() === "banktransfer")
            .map((x) => x.amount)
            .reduce((x, sum) => Number(sum) + Number(x), 0)
          : "",
    },
    {
      label: "Others (in Rs.)",
      value: (col) =>
        col.payment
          ? col.payment
            .filter((x) => x.mode.toLowerCase() === "others")
            .map((x) => x.amount)
            .reduce((x, sum) => Number(sum) + Number(x), 0)
          : "",
    },

    {
      label: "Online (in Rs.)",
      value: (col) =>
        col.payment
          ? col.payment
            .filter((x) => x.mode.toLowerCase() === "online")
            .map((x) => x.amount)
            .reduce((x, sum) => Number(sum) + Number(x), 0)
          : "",
    },
    {
      label: "Paytm (in Rs.)",
      value: (col) =>
        col.payment
          ? col.payment
            .filter((x) => x.mode.toLowerCase() === "paytm")
            .map((x) => x.amount)
            .reduce((x, sum) => Number(sum) + Number(x), 0)
          : "",
    },
    {
      label: "Booking Date",
      value: (col) => col.bookingDate && convertFirstoreDate(col.bookingDate),
    },
    { label: "Created By", value: (col) => col.createdBy ? col.createdBy.userName : "" },

    {
      label: "Payment Details",
      value: (col) => (col.payment ? createStringForPayments(col.payment) : ""),
    },
    {
      label: "Product Details",
      value: (col) => (col.products ? createStringForProuducts(col.products)
        : col.sellProducts && col.rentalProducts ? createStringForProuducts(col.sellProducts) + ' | ' + createStringForProuducts(col.rentalProducts)
          : col.sellProducts ? createStringForProuducts(col.sellProducts)
            : col.rentalProducts ? createStringForProuducts(col.rentalProducts)
              : col.productDetails ? "Discarded Product Name: " + col.productDetails.productName +
                ", Quantity: " + col.productDetails.quantity + ", Remarks: " + col.productDetails.remarks
                : ""),
    },
    {
      label: "Academy Details",
      value: (col) => (col.type === 'academy' ? createAcademySummary(col)
        : ""),
    },
    {
      label: "Remarks",
      value: (col) => (col.remarks ? col.remarks : ""),
    },
  ];

  const changePaymentMode = (transaction) => {
    const amountPaid = transaction.payment.reduce((total, n) => total + n.amount, 0);
    setSelectedTransactionId(transaction.id);
    setChangeAmount(amountPaid);
    setShowChangePaymentModal(true);
  }
  const checkPaymentModeStatus = (transaction) => {
    let isDisabled = true;
    if (transaction.amountPaid > 0 && !transaction.paymentMode.includes('online')) {
      if (user.role === ROLES.ADMIN || user.role === ROLES.ACCOUNTANT) {
        isDisabled = false;
      } else {
        if (moment(transaction.createdDate).format('DD-MM-YYYY') === moment().format('DD-MM-YYYY')) {// change is requested the same day
          isDisabled = false;
        }
      }
    }

    return isDisabled;
  }

  useEffect(() => {
    loadData();
  }, [selectedArena, selectedFilters]);// eslint-disable-line react-hooks/exhaustive-deps

  const onApply = (filters) => {
    setIsLoading(true);
    setSelectedFilters(filters);
    setShowFilter(false);
  };

  const openFilter = () => {
    setShowFilter(true);
  };

  const openTransactionDetail = (data) => () => {
    setShowDetails(data);
  };

  const startDate = convertFirstoreDate(
    selectedFilters.startDate,
    "DD MMM YYYY"
  );
  const endDate = convertFirstoreDate(selectedFilters.endDate, "DD MMM YYYY");
  const fileName = 'Accounting ' + startDate + " - " + endDate;
  const tallyFileName = 'Tally File ' + startDate + " - " + endDate;
  const sheetName = moment().format('MMMM Do YYYY, h_mm a');
  const sheets = [
    {
      name: sheetName,
      columns: tableHeader,
      dataSet: data,
    },
  ];

  const createPartyLedger = (data) => {
    let ledgerName;
    if (data.bookingStatus === "cancelled") {
      ledgerName = data.source === THIRD_PARTY_BOOKING_SOURCE.HUDLE || data.source === THIRD_PARTY_BOOKING_SOURCE.PLAYO || data.source === THIRD_PARTY_BOOKING_SOURCE.KHELO_MORE?
      data.source + " Credit Note" :"Credit Note" ;
      return ledgerName;
    }
    if (data.paymentMode) {
      if (data.paymentMode.length == 1) {
        ledgerName = data.arenaId ?
          data.source === THIRD_PARTY_BOOKING_SOURCE.HUDLE || data.source === THIRD_PARTY_BOOKING_SOURCE.PLAYO || data.source === THIRD_PARTY_BOOKING_SOURCE.KHELO_MORE?
            data.source + ' Sales ' + data.arenaId.slice(4)
            : data.platform ?
              'App Sales ' + data.arenaId.slice(4)
              : capitalizeFirstLetter(data.paymentMode[0]) + ' Sales ' + data.arenaId.slice(4)
          : capitalizeFirstLetter(data.paymentMode[0]) + ' Sales ';
      } else if (data.subtype === TRANSACTION_SUBTYPES.complimentaryProduct || data.bookingStatus === "productAlloted") {
        ledgerName = data.arenaId ? 'Complimentary Sales ' + data.arenaId.slice(4) : 'Complimentary Sales ';
      } else if (
        data.subtype === TRANSACTION_SUBTYPES.productDiscard ||
        data.subtype === TRANSACTION_SUBTYPES.cafeProductDiscard
      ) {
        ledgerName = data.arenaId ? 'Discard Sales ' + data.arenaId.slice(4) : 'Discard Sales ';

      } else if (
        data.subtype === TRANSACTION_SUBTYPES.assignToAcademy
      ) {
        ledgerName = data.arenaId ? 'Academy Consumption ' + data.arenaId.slice(4) : 'Discard Sales ';

      } else {
        ledgerName = data.arenaId ? 'Customer Sales ' + data.arenaId.slice(4) : 'Customer Sales ';
      }
    }
    return ledgerName;
  }

  const createNarration = (data) => {
    let narration = "";

    const sports = data.alternativeSport ? data.alternativeSport.text : data.sport ? data.sport.sportName : "";
    if ((data.type === 'booking' && data.subtype === 'regularBooking') || (data.type === 'booking' && data.subtype === 'thirdPartyBooking')) {
      if (data.bookingStatus === "extended") {
        narration = "Extension - ";
      }
      const bookingNarration = capitalizeFirstLetter(data.type) + ' for ' + data.userName + '(' + data.userMobileNumber + '). Sport - ' + sports + ' | Slot - ' + convertFirstoreDateForExcel(data.bookingDate) + '-' + data.slot.interval.start + ':' + data.slot.interval.end + ' | Court - ' + data.court.courtName;
      if (data.bookingStatus === "rescheduled") {
        narration = "Reschedule -  This Booking - " + bookingNarration + " --- ";
        if (data.rescheduleTo) {
          narration = narration + "Rescheduled to :- Date " + new Date(data.rescheduleTo.bookingDate.seconds * 1000).toLocaleDateString() + " | Slot - " + data.rescheduleTo.slotName + " | Court - " + data.rescheduleTo.courtName;
        }
        if (data.rescheduledFrom) {
          narration = narration + "Rescheduled From :- Date " + new Date(data.rescheduledFrom.bookingDate.seconds * 1000).toLocaleDateString() + " | Slot - " + data.rescheduledFrom.slotName + " | Court - " + data.rescheduledFrom.courtName;
        }
      } else if (data.bookingStatus === "cancelled") {

        narration = `Cancellation - ${bookingNarration} | Old Receipt Number - ${data.cancelledReceiptNumber || ''}`;
      } else {
        narration = narration + bookingNarration;
      }
    } else if (data.type === 'academy' && data.subtype === 'enrollment') {
      const newStudent = data.registrationFees === 0 ? "No" : "Yes";
      narration = capitalizeFirstLetter(data.type) + ' for ' + data.studentName + '(' + data.userMobileNumber + '). Sport - ' + sports + ' | From - ' + new Date(data.startDate.seconds * 1000).toLocaleDateString() + 'to' + new Date(data.endDate.seconds * 1000).toLocaleDateString() + ' | Batch - ' + data.batchName + ' | New Enrollment - ' + newStudent;
    } else if (data.type === TRANSACTION_TYPE.product) {
      if (data.bookingStatus === 'productAlloted') {
        narration = "Product Allocation to student";
      } else if (data.bookingStatus === 'productDiscard') {
        const product = data.productDetails;
        narration = "Product Discarded. Product Name - " + product.productName + " | Qty - " + product.quantity + " | Remarks - " + product.remarks;
      } else if (data.bookingStatus === 'academyProductDiscard') {
        const product = data.productDetails;
        narration = "Academy Product Discarded. Product Name - " + product.productName + " | Qty - " + product.quantity + " | Remarks - " + product.remarks;
      } else if (data.bookingStatus === 'cafeProductDiscard') {
        const product = data.productDetails;
        narration = "Cafe Product Discarded. Product Name - " + product.productName + " | Qty - " + product.quantity + " | Remarks - " + product.remarks;
      }
    } else if (data.type === TRANSACTION_TYPE.cafe) {
      if (data.subtype === TRANSACTION_SUBTYPES.cafeProductDiscard) {
        const product = data.productDetails;
        narration = "Cafe Product Discarded. Product Name - " + product.productName + " | Qty - " + product.quantity + " | Remarks - " + product.remarks;

      }
    } else if (data.type === TRANSACTION_TYPE.receipt) {
        narration = data.remarks;
    }
    if (data.paymentMode) {
      if (data.paymentMode.length > 1) {
        let paymentDetails;
        for (var i = 0; i < data.payment.length; i++) {
          const pay = data.payment[i];
          if (i === 0) {
            paymentDetails = "Rs. " + pay.amount + "/ - " + capitalizeFirstLetter(pay.mode) + ",";
          } else if (i === data.payment.length - 1) {
            paymentDetails = paymentDetails + "Rs. " + pay.amount + "/ - " + capitalizeFirstLetter(pay.mode);
          } else {
            paymentDetails = paymentDetails + "Rs. " + pay.amount + "/ - " + capitalizeFirstLetter(pay.mode) + ",";
          }
        }
        narration = narration ? narration + ' | Payment Details - ' + paymentDetails : 'Payment Details - ' + paymentDetails;
      }
    }
    return narration;
  }

  const createSaleType = (data) => {
    let saleType = "";
    const sports = data.alternativeSport ? data.alternativeSport.text : data.sport ? data.sport.sportName : "";
    switch (data.type) {
      case TRANSACTION_TYPE.booking:
        switch (data.subtype) {
          case TRANSACTION_SUBTYPES.regularBooking:
            saleType = sports + " Pay & Play";
            break;
          case TRANSACTION_SUBTYPES.thirdPartyBooking:
            saleType = sports + " Pay & Play";
            break;
          case TRANSACTION_SUBTYPES.partPayment:
            saleType = sports + " Pay & Play";
            break;

          //bulkbooking
          case TRANSACTION_SUBTYPES.bulkBooking:
            saleType = sports + " Pay & Play";
            break;

          //group membership
          case TRANSACTION_SUBTYPES.groupBooking:
            saleType = sports + " Pay & Play";
            break;

          //individual membership
          case TRANSACTION_SUBTYPES.individualBooking:
            saleType = sports + " Pay & Play";
            break;

          default:
            saleType = sports + " Pay & Play";
            break;

        }
        break;

      //product
      case TRANSACTION_TYPE.product:
        switch (data.subtype) {
          case TRANSACTION_SUBTYPES.productSell:
            saleType = "Sports Product Sale";
            break;
          case TRANSACTION_SUBTYPES.productRent:
            saleType = "Sports Product Rental";
            break;
          case TRANSACTION_SUBTYPES.productDiscard:
            saleType = "Sports Product Sale";
            break;
          case TRANSACTION_SUBTYPES.complimentaryProduct:
            saleType = "Sports Product Sale";
            break;

          case TRANSACTION_SUBTYPES.assignToAcademy:
            saleType = "Sports Product Sale";
            break;
          default:
            saleType = "Sports Product Sale";
            break;
        }
        break;

      /// cafe
      case TRANSACTION_TYPE.cafe:
        switch (data.subtype) {
          case TRANSACTION_SUBTYPES.cafeProductSell:
            saleType = "Cafe Product Sale";
            break;
          case TRANSACTION_SUBTYPES.cafeProductDiscard:
            saleType = "Cafe Product Sale";
            break;
          default:
            saleType = "Cafe Product Sale";
            break;
        }
        break;

      /// Academy
      case TRANSACTION_TYPE.academy:
        saleType = sports + " Academy";
        break;

      /// Receipts
      case TRANSACTION_TYPE.receipt:
        const sportsName = data.sport ? sports : 'Generic';
        switch (data.subtype) {
          case TRANSACTION_SUBTYPES.receiptPlayAllTournament:
            saleType = "Open Tournament";
            break;
          case TRANSACTION_SUBTYPES.receiptOfflineCredits:
            saleType = " Offline Credits";
            break;
          case TRANSACTION_SUBTYPES.receiptMisc:
            saleType = " Misc. Sale";
            break;

        }
        break;


      /// flexi membership
      case TRANSACTION_TYPE.flexiMembership:
        saleType = sports + " Pay & Play";
        break;

      /// expenses
      case TRANSACTION_TYPE.expenses:
        saleType = sportsName + capitalizeFirstLetter(data.subtype);
        break;

      /// wallet
      case TRANSACTION_TYPE.wallet:
        const sportName = data.sports.length > 1 ? 'Generic' : data.sports[0].sportName;
        saleType = sportName + " Wallet Recharge";
        break;

      default:
        saleType = "Generic - " + capitalizeFirstLetter(data.subtype);
        break;



    }

    return saleType;
  }

  const createReference = (data) => { // this is the cost center in tally - to be used in CRM
    let saleType = "";
    const sports = data.alternativeSport ? data.alternativeSport.text : data.sport ? data.sport.sportName : "";
    switch (data.type) {
      case TRANSACTION_TYPE.booking:
        switch (data.subtype) {
          case TRANSACTION_SUBTYPES.regularBooking:
            saleType = data.bookingStatus === BOOKING_STATUS.EXTENDED
              ? sports + " Pay & Play Extension " + arenaAccountsName
              : sports + " Pay & Play " + arenaAccountsName;
            break;
          case TRANSACTION_SUBTYPES.thirdPartyBooking:
            saleType = data.bookingStatus === BOOKING_STATUS.EXTENDED ?
              sports + " Pay & Play Extension " + arenaAccountsName
              : sports + " Online Non Pass Sale " + arenaAccountsName;
            break;
          case TRANSACTION_SUBTYPES.partPayment:
            saleType = data.thirdParty ? sports + " Online Non Pass Sale " + arenaAccountsName : sports + " Pay & Play " + arenaAccountsName;
            break;

          //bulkbooking
          case TRANSACTION_SUBTYPES.bulkBooking:
            saleType = sports + " Bulk Booking " + arenaAccountsName;
            break;

          //group membership
          case TRANSACTION_SUBTYPES.groupBooking:
            saleType = sports + " Membership " + arenaAccountsName;
            break;

          //individual membership
          case TRANSACTION_SUBTYPES.individualBooking:
            saleType = sports + " Membership " + arenaAccountsName;
            break;

          default:
            saleType = sports + " Pay & Play " + arenaAccountsName;
            break;

        }
        break;

      //product
      case TRANSACTION_TYPE.product:
        switch (data.subtype) {
          case TRANSACTION_SUBTYPES.productSell:
            saleType = "Sports Product Sale " + arenaAccountsName;
            break;
          case TRANSACTION_SUBTYPES.productRent:
            saleType = "Sports Product Rental " + arenaAccountsName;
            break;
          case TRANSACTION_SUBTYPES.productDiscard:
            saleType = "Sports Product Sale " + arenaAccountsName;
            break;
          case TRANSACTION_SUBTYPES.complimentaryProduct:
            saleType = "Sports Product Sale " + arenaAccountsName;
            break;

          case TRANSACTION_SUBTYPES.assignToAcademy:
            saleType = "Sports Product Sale " + arenaAccountsName;
            break;
          default:
            saleType = "Sports Product Sale " + arenaAccountsName;
            break;
        }
        break;

      /// cafe
      case TRANSACTION_TYPE.cafe:
        switch (data.subtype) {
          case TRANSACTION_SUBTYPES.cafeProductSell:
            saleType = "Cafe Product Sale " + arenaAccountsName;
            break;
          case TRANSACTION_SUBTYPES.cafeProductDiscard:
            saleType = "Cafe Product Sale " + arenaAccountsName;
            break;
          default:
            saleType = "Cafe Product Sale " + arenaAccountsName;
            break;
        }
        break;

      /// Academy
      case TRANSACTION_TYPE.academy:
        saleType = data.isRenewed ? sports + " Academy Renewal " + arenaAccountsName : sports + " Academy Enrollment " + arenaAccountsName;
        break;

      /// Receipts
      case TRANSACTION_TYPE.receipt:
        const sportsName = data.sport ? sports : 'Generic';
        switch (data.subtype) {
          case TRANSACTION_SUBTYPES.receiptPlayAllTournament:
            saleType = sportsName + " Tournament " + arenaAccountsName;
            break;
          case TRANSACTION_SUBTYPES.receiptOfflineCredits:
            saleType = sportsName + " Offline Credits " + arenaAccountsName;
            break;
          case TRANSACTION_SUBTYPES.receiptMisc:
            saleType = sportsName + " Misc Receipt " + arenaAccountsName;
            break;

        }
        break;


      /// expenses
      case TRANSACTION_TYPE.expenses:
        saleType = sportsName + capitalizeFirstLetter(data.subtype);
        break;

      /// flexi membership
      case TRANSACTION_TYPE.flexiMembership:
        saleType = sports + " Membership " + arenaAccountsName;
        break;

      
      /// wallet
      case TRANSACTION_TYPE.wallet:
        const sportName = data.sports.length > 1 ? 'Generic' : data.sports[0].sportName;
        saleType = sportName +" Pay & Play " + arenaAccountsName;
        break;

      default:
        console.log('data.subtype ', data);
        saleType = "Generic - " + capitalizeFirstLetter(data.subtype);
        break;


    }

    return saleType;
  }

  function createItemName(data) {
    let saleType = "";
    const sports = data.alternativeSport ? data.alternativeSport.text : data.sport ? data.sport.sportName : "";
    switch (data.type) {
      case TRANSACTION_TYPE.booking:
        switch (data.subtype) {
          case TRANSACTION_SUBTYPES.regularBooking:
            saleType = sports + " Booking";
            break;
          case TRANSACTION_SUBTYPES.thirdPartyBooking:
            saleType = sports + " Online Booking";
            break;
          case TRANSACTION_SUBTYPES.partPayment:
            saleType = data.thirdParty ? sports + " Online Booking" : sports + " Booking";
            break;

          //bulkbooking
          case TRANSACTION_SUBTYPES.bulkBooking:
            saleType = sports + " Bulk Booking";
            break;

          //group membership
          case TRANSACTION_SUBTYPES.groupBooking:
            saleType = sports + " Membership";
            break;

          //individual membership
          case TRANSACTION_SUBTYPES.individualBooking:
            saleType = sports + " Membership";
            break;

          default:
            saleType = sports + " Booking";
            break;

        }
        break;

      // if (data.productName) {
      //   if (
      //     data.subtype === TRANSACTION_SUBTYPES.productSell ||
      //     data.subtype === TRANSACTION_SUBTYPES.cafeProductSell ||
      //     data.subtype === TRANSACTION_SUBTYPES.productDiscard ||
      //     data.subtype === TRANSACTION_SUBTYPES.academyProductDiscard ||
      //     data.subtype === TRANSACTION_SUBTYPES.cafeProductDiscard ||
      //     data.subtype === TRANSACTION_SUBTYPES.complimentaryProduct||
      //     data.subtype === TRANSACTION_SUBTYPES.assignToAcademy
      //   ) {
      //     saleType = data.productName
      //   } else { // in case of rent identify if its a shoe rental or racquet rental
      //     if (data.productName.includes('Shoe')) {
      //       saleType = data.sport.sportName + " Shoe Rental";
      //     } else { // asuming we'll only rent racket apart from 
      //       saleType = data.sport.sportName + " Racket Rental";
      //     }
      //   }
      // } 

      //product
      case TRANSACTION_TYPE.product:
        switch (data.subtype) {
          case TRANSACTION_SUBTYPES.productSell:
            saleType = data.productName;
            break;
          case TRANSACTION_SUBTYPES.productRent:
            if (data.productName.includes('Shoe')) {
              saleType = sports + " Shoe Rental";
            } else { // asuming we'll only rent racket apart from 
              saleType = sports + " Racket Rental";
            }
            break;
          case TRANSACTION_SUBTYPES.productDiscard:
            saleType = data.productName;
            break;
          case TRANSACTION_SUBTYPES.complimentaryProduct:
            saleType = data.productName;
            break;

          case TRANSACTION_SUBTYPES.assignToAcademy:
            saleType = data.productName;
            break;
          default:
            saleType = data.productName;
            break;
        }
        break;

      /// cafe
      case TRANSACTION_TYPE.cafe:
        switch (data.subtype) {
          case TRANSACTION_SUBTYPES.cafeProductSell:
            saleType = data.productName;
            break;
          case TRANSACTION_SUBTYPES.cafeProductDiscard:
            saleType = data.productName;
            break;
          default:
            saleType = data.productName;
            break;
        }
        break;

      /// Academy
      case TRANSACTION_TYPE.academy:
        saleType = sports + " Academy Fee";
        break;

      /// Receipts
      case TRANSACTION_TYPE.receipt:
        const sportsName = data.sport ? sports : 'Generic';
        switch (data.subtype) {
          case TRANSACTION_SUBTYPES.receiptPlayAllTournament:
            saleType = sportsName + " Open Tournament";
            break;
          case TRANSACTION_SUBTYPES.receiptOfflineCredits:
            saleType = sportsName + " Offline Credits";
            break;
          case TRANSACTION_SUBTYPES.receiptMisc:
            saleType = sportsName + " Misc. Sale";
            break;

        }
        break;


      /// expenses
      case TRANSACTION_TYPE.expenses:
        saleType = sportsName + capitalizeFirstLetter(data.subtype);
        break;


      /// flexi membership

      case TRANSACTION_TYPE.flexiMembership:
        saleType = sports + " Membership";
        break;

      /// wallet
      case TRANSACTION_TYPE.wallet:
        const sportName = data.sports.length > 1 ? 'Generic' : data.sports[0].sportName;
        saleType = sportName + " Wallet Recharge";
        break;

      default:
        saleType = "Generic - " + capitalizeFirstLetter(data.subtype);
        break;


    }


    return saleType;
  }


  const tallyTableHeader = [
    { label: "Sr No", value: (col) => col.creditNoteNumber ? col.creditNoteNumber : col.receiptNumber },
    { label: "Voucher Type", value: (col) => col.arenaId ? col.bookingStatus !== "cancelled" ? ('Tax Invoice-' + col.arenaId.slice(4)) : ('Credit Note-' + col.arenaId.slice(4)) : "" },
    { label: "Bill Date", value: (col) => convertFirstoreDateForExcel(col.createdDate) },
    { label: "Reference", value: (col) => createReference(col), },
    { label: "Party Ledger", value: (col) => createPartyLedger(col) },
    { label: "Party Address1", value: "state", },
    { label: "Party Address2", },
    { label: "sale type", value: (col) => createSaleType(col), },
    { label: "Item Name", value: (col) => createItemName(col) }, // to be used for products
    { label: "Item Description", },
    { label: "Quantity", value: (col) => (col.productQuantity ? col.productQuantity : col.numberOfCustomer ? col.numberOfCustomer : 1) },
    { label: "Basic Amount", value: (col) => (col.productQuantity && col.taxableAmount ? (Math.round((col.taxableAmount / col.productQuantity) * 100) / 100) : col.taxableAmount && col.numberOfCustomer ? (Math.round((col.taxableAmount / col.numberOfCustomer) * 100) / 100) : col.taxableAmount ? col.taxableAmount : col.amountPaid) },
    { label: "Discount", value: (col) => (col.discountAmount ? col.discountAmount : 0) },
    { label: "Amount", value: (col) => col.taxableAmount ? col.taxableAmount : col.amountPaid },
    { label: "IGST Amount", value: "igst" },
    { label: "CGST Amount", value: "cgst" },
    { label: "SGST Amount", value: "sgst" },
    { label: "IGST 18 Amount", value: () => 0 },
    { label: "CGST 9 Amount", value: () => 0 },
    { label: "SGST 9 Amount", value: () => 0 },
    { label: "IGST 28 Amount", value: () => 0 },
    { label: "CGST 14 Amount", value: "cess" },
    { label: "SGST 14 Amount", value: () => 0 },
    { label: "Godown Name", value: () => "Main Location" },
    { label: "Batch Name", },
    { label: "Narration", value: (col) => createNarration(col) },
    { label: "Country", value: () => "India" },
    { label: "State Name", value: "state" },
    { label: "GSTIN", },
    { label: "RoundOff", value: () => 0 },
    { label: "Registration Type", },
    { label: "item disc", },
    { label: "sales ledger", value: (col) => createSaleType(col), },
    { label: "GST %", value: (col) => col.gst ? Math.round(col.gst * 100) + "%" : "0%" },
    { label: "HSN", value: "hsn" },
    { label: "Transaction Id", value: "id" },
    { label: "Time of Transaction", value: "createdTime" },
    { label: "Payment Gateway ID", value: (col) => col.paymentGateWayId ? col.paymentGateWayId : "" },
    { label: "Platform", value: (col) => col.platform ? col.platform : "" },
    { label: "Payment Status", value: (col) => col.paymentStatus ? col.paymentStatus : "" },
    { label: "Booking Type", value: "type" },
    { label: "Sub Category", value: (col) => getSubtypeLabel(col.subtype) },
    { label: "Arena", value: "arenaName" },
    { label: "Sport", value: (col) => (col.alternativeSport ? col.alternativeSport.text : col.sport ? col.sport.sportName : "") },
    { label: "Customer Name", value: "userName" },
    { label: "Customer Number",value: (col) => (col.userMobileNumber && !(user.role === ROLES.AUDITOR) ? col.userMobileNumber : ""),},
  ];

  const tallySheets = [
    {
      name: sheetName,
      columns: tallyTableHeader,
      dataSet: tallyData,
    },
  ];

  return (
    <div style={{ padding: "16px" }}>
      <div style={{ paddingBottom: "16px" }}>
        <Toast
          showMessage={showMessage}
          setShowMessage={setShowMessage}
          message={messageContent.message}
          messageHeader={messageContent.header}
          color={messageContent.color}
        />
        <div className='dashboard'>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >

            <div>
              <span > Accounting Report </span>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  paddingRight: "10px",
                }}
              >
                ( Range: {startDate}- {endDate})
              </span>
            </div>
            <div style={{ alignItems: "center" }}>
              <span>
                <Button
                  onClick={() => { setShowEditReceiptModal(true) }}
                  disabled={user.role === ROLES.ADMIN || user.role === ROLES.ACCOUNTANT ? false : true}
                >
                  <Icon
                    name={"file alternate outline"}
                  />
                  Update ReceiptNumber
                </Button>
                
                {user.role !== ROLES.PARTNER ? <span>
                  <ExportToExcel
                    disabled={data.length === 0 || !(user.role === ROLES.ADMIN || user.role === ROLES.ACCOUNTANT)}
                    sheets={tallySheets}
                    fileName={tallyFileName}
                    buttonName="Tally Report"
                  />
                </span> : null}

                <Button onClick={openFilter} name={"filter"}>
                  <Icon
                    color={selectedFilters.isFilterApplied ? "orange" : "grey"}
                    name={"filter"}
                  />
                  Filter
                </Button>

              </span>
            </div>
          </div>

        </div>
      </div>

      {showFilter ? (
        <BookingFilters
          onApply={onApply}
          selectedFilters={selectedFilters}
          setLoadData={setIsLoading}
          setShowFilter={setShowFilter}
          showFilter
          role={user.role}
        />
      ) : null}
      {!!showDetails ? (
        <TransactionDetails
          selectedData={showDetails}
          setShowDetails={setShowDetails}
          role={user.role}
          setMessage={setShowMessage}
          setMessageContent={setMessageContent}
          isLoading={setIsLoading}
        />
      ) : null}
      {isLoading ? (
        <GenericLoader />
      ) : data.length === 0 ? (
        "No data available"
      ) : (

        <Table celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Date of Transaction</Table.HeaderCell>
              <Table.HeaderCell>Customer Detail</Table.HeaderCell>
              <Table.HeaderCell>Transaction Amount</Table.HeaderCell>
              <Table.HeaderCell>Sport</Table.HeaderCell>
              <Table.HeaderCell>Arena</Table.HeaderCell>
              <Table.HeaderCell>Source</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Created By</Table.HeaderCell>
              <Table.HeaderCell>Receipt No.</Table.HeaderCell>
              <Table.HeaderCell>Payment Mode</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map((x) => {
              return (
                <Table.Row
                  style={
                    x.amountDue ? { backgroundColor: colors.red["50"] } : {}
                  }
                  key={x.receiptNumber}
                >
                  <Table.Cell>
                    <div>
                      {convertFirstoreDate(x.createdDate)}, {x.createdTime}
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <div>{x.userName}</div>
                    <div>{!(user.role === ROLES.PARTNER || user.role === ROLES.AUDITOR) ? x.userMobileNumber : null}</div>
                  </Table.Cell>
                  <Table.Cell>
                    Rs.{x.amountPaid}/-{" "}
                    <div style={{ color: colors.red["300"] }}>
                      {x.amountDue ? "Due Amount: " + x.amountDue : ""}
                    </div>
                  </Table.Cell>
                  <Table.Cell>{x.alternativeSport ? x.alternativeSport.text : x.sport?.sportName}</Table.Cell>
                  <Table.Cell>{x.arenaName}</Table.Cell>
                  <Table.Cell>{getSubtypeLabel(x.source)}</Table.Cell>
                  <Table.Cell style={{ textTransform: "capitalize" }}>
                    {x.type}, {getSubtypeLabel(x.subtype)}
                    {getBookingStatusLabel(x.bookingStatus)}
                  </Table.Cell>
                  <Table.Cell>{x.createdBy?.userName || "-"}</Table.Cell>
                  <Table.Cell onClick={openTransactionDetail(x)}>
                    <div
                      style={{
                        textDecoration: "underline",
                        color: colors.blue[500],
                      }}
                    >
                      {x.creditNoteNumber ? x.creditNoteNumber :x.receiptNumber}
                    </div>
                    {x.rescheduledFrom && x.rescheduleTo ? null :
                      x.rescheduledFrom ? (
                        <div style={{ color: "red" }}>New Slot</div>
                      ) : x.rescheduleTo ? (
                        <div style={{ color: "red" }}>Old Slot</div>
                      ) : x.cancelled ? (
                        <div style={{ color: "grey" }}>Cancelled</div>
                      ) :  x.paymentStatus === PAYMENT_STATUS.PENDING && <div style={{ color: "red" }}>Payment Pending</div>}
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      disabled={checkPaymentModeStatus(x)}
                      onClick={() => { changePaymentMode(x) }}
                    >
                      <Icon name={"payment"} /> Change
                    </Button>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      )}

      {showEditReceiptModal ? (
        <EditReceiptNumber
          showModal={setShowEditReceiptModal}
          setReload={setReload}
          setMessage={setMessageContent}
          isLoading={setIsLoading}
        />
      ) : null}
      {showChangePaymentModal ? (
        <ChangePaymentMode
          showModal={setShowChangePaymentModal}
          setReload={setReload}
          setMessage={setMessageContent}
          isLoading={setIsLoading}
          amount={changeAmount}
          transactionId={selectedTransactionId}
        />
      ) : null}
    </div>
  );
};

export default NewAccountingReport;
