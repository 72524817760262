import React from "react";
import { topSectionData, footerSection, NavSection } from "./HeaderAndFooter";
import { Helmet } from "react-helmet";
import { Fade, Zoom, } from 'react-reveal';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const contentSection = (
  <>
    <div class='dispfbanner'>
      <div class='bannerarea'>
        <h4 data-aos='zoom-in-down'>Play Box-Cricket (Gully Cricket)</h4>
        <h1 data-aos='fade-up'>
          We Are Redefining Sports. Experience The Difference
        </h1>
        <div class='btarea' data-aos='zoom-in'></div>
      </div>
    </div>
    <div class='aboutarea ' data-aos='zoom-in'>
      <div class='aboutbox '>
        <div class='boxes '>
          <svg>
            <use href='#park'></use>
          </svg>
          <h5> Only cricket turf in the city</h5>
        </div>
        <div class='boxes '>
          <svg viewBox='0 0 55.558 47.814'>
            <use href='#stadium'></use>
          </svg>
          <h5> Best in Class Infra</h5>
        </div>
        <div class='boxes '>
          <svg>
            <use href='#football'></use>
          </svg>
          <h5> 13 mm Turf </h5>
        </div>
      </div>
    </div>
  </>
);

const mainSection = (
  <>
    <main>
      

      <section class='partnersec '>
        <div class='container'>
          <div class='innerbx pb-5'>
            <div class='contentarea '>
              <Zoom>  <h4 >PlayAll Box-Cricket Arena</h4>
                <h2 >Delhi International School, Sector-23 Dwarka, Delhi</h2>
                <p>With three 7-Vs-7 International standard 13 & 50 mm <b> Turf </b>, PlayAll is one of the best mini <b>Cricket Ground</b> in Dwarka. With Amenities like Ample Parking and online bookings, we ensure that your Cricket experience is hassel free and awesome. </p>
              </Zoom>
              <span class='address'>
                Address - Delhi International School Sector 23 Dwarka | Contact - 011-411-69191
              </span>

              <div class='btarea mb-3'>
                <a
                  href='https://maps.app.goo.gl/C56XtCyQdKc39yBg6'
                  class='btwhite1'
                >

                  <svg width='34' height='41'>
                    <use href='#googleMap'></use>
                  </svg>
                  <Fade left> Directions</Fade>
                </a>
              </div>

            <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  href='https://play.google.com/store/apps/details?id=com.playallrn'
                  class='btorng'
                  style={{ background: "#000000" }}
                  target="_blank"
                >
                  {" "}
                  <img alt='' src='images/playStore.png' style={{ height: "120px" }} />
                </a>


                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://apps.apple.com/us/app/play-all/id6480510323'
                  style={{ background: "#000000" }}

                  target="_blank"
                >
                  <img src='images/appStore.png' alt='' style={{ height: "120px" }} />
                </a>
              </div>


            </div>
            <div class=' rightbx startplayarea1 d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade >
                  <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1400} swipeable={true} emulateTouch={true}>
                    <img src='images/gallery/DIS_dwarka_box_cricket_1.png' alt='cricket turf' />
                    <img src='images/gallery/DIS_dwarka_box_cricket_2.png' alt='box-cricket turf' />
                    <img src='images/gallery/DIS_dwarka_box_cricket_3.png' alt='cricket ground near me' />
                  </Carousel></Fade>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class='partnersec '>
        <div class='container'>
          <div class='innerbx pb-5'>
            <div class=' rightbx startplayarea1 d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade >
                  <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1400} swipeable={true} emulateTouch={true}>
                    <img src='images/gallery/jis_pitampura_box_cricket_1.png' alt='cricket turf' />
                    <img src='images/gallery/jis_pitampura_box_cricket_2.png' alt='box-cricket turf' />
                    <img src='images/gallery/jis_pitampura_box_cricket_3.png' alt='cricket ground near me' />
                  </Carousel></Fade>
              </div>
            </div>
            <div class='contentarea '>
              <Zoom>  <h4 >PlayAll Box-Cricket Arena</h4>
                <h2 >JIS Pitampura, Delhi</h2>
                <p>With two 7-Vs-7 International standard 13 & 50 mm <b> Turf </b>, PlayAll is one of the best mini <b>Cricket Ground</b> in Dwarka. With Amenities like Ample Parking and online bookings, we ensure that your Cricket experience is hassel free and awesome. </p>
              </Zoom>
              <span class='address'>
                Address -  Jagannath International School D Block, Block D, Pushpanjali Enclave, Pitampura, Delhi, 110083 | Contact -  011-411-69012
              </span>

              <div class='btarea mb-3'>
                <a
                  href='https://maps.app.goo.gl/m2rZ9idXZ9dBtM9P6'
                  class='btwhite1'
                >

                  <svg width='34' height='41'>
                    <use href='#googleMap'></use>
                  </svg>
                  <Fade left> Directions</Fade>
                </a>
              </div>

              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  href='https://play.google.com/store/apps/details?id=com.playallrn'
                  class='btorng'
                  style={{ background: "#000000" }}
                  target="_blank"
                >
                  {" "}
                  <img alt='' src='images/playStore.png' style={{ height: "120px" }} />
                </a>


                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://apps.apple.com/us/app/play-all/id6480510323'
                  style={{ background: "#000000" }}

                  target="_blank"
                >
                  <img src='images/appStore.png' alt='' style={{ height: "120px" }} />
                </a>
              </div>


            </div>

          </div>
        </div>
      </section>

      <section class='partnersec '>
        <div class='container'>
          <div class='innerbx pb-5'>
            <div class='contentarea '>
              <Zoom>  <h4 >PlayAll Box-Cricket Arena</h4>
                <h2 >South Extension 1-Delhi</h2>
                <p>With two 7-Vs-7 International standard 13 & 50 mm <b> Turf </b>, PlayAll is one of the best mini <b>Cricket Ground</b> in South Delhi. With Amenities like Ample Parking and online bookings, we ensure that your Cricket experience is hassel free and awesome. </p>
              </Zoom>
              <span class='address'>
                Address - Block D, Opposite Bade Khan Gumbadh, South Extension 1, New Delhi, Delhi| Contact - 011-411-71906
              </span>

              <div class='btarea mb-3'>
                <a
                  href='https://maps.app.goo.gl/Qyt6MSDuKaAUiPVr7'
                  class='btwhite1'
                >

                  <svg width='34' height='41'>
                    <use href='#googleMap'></use>
                  </svg>
                  <Fade left> Directions</Fade>
                </a>
              </div>

              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  href='https://play.google.com/store/apps/details?id=com.playallrn'
                  class='btorng'
                  style={{ background: "#000000" }}
                  target="_blank"
                >
                  {" "}
                  <img alt='' src='images/playStore.png' style={{ height: "120px" }} />
                </a>


                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://apps.apple.com/us/app/play-all/id6480510323'
                  style={{ background: "#000000" }}

                  target="_blank"
                >
                  <img src='images/appStore.png' alt='' style={{ height: "120px" }} />
                </a>
              </div>


            </div>
            <div class=' rightbx startplayarea1 d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade >
                  <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1400} swipeable={true} emulateTouch={true}>
                    <img src='images/gallery/southex_1.png' alt='cricket turf' />
                    <img src='images/gallery/southex_2.png' alt='box-cricket turf' />
                    <img src='images/gallery/southex_3.png' alt='cricket ground near me' />
                  </Carousel></Fade>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class='partnersec '>
        <div className='container'>
          <div class='innerbx pb-5'>
            <div class=' rightbx startplayarea d-flex justify-content-center'>
              <div class='startplaybox'>

                <Fade >
                  <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1400} swipeable={true} emulateTouch={true}>

                    <img src='images/gallery/svis_dwarka_1.png' alt='cricket turf' />
                    <img src='images/gallery/svis_dwarka_2.png' alt='box-cricket turf' />
                    <img src='images/gallery/svis_dwarka_3.png' alt='cricket ground near me' />
                  </Carousel></Fade>

              </div>
            </div>

            <div class='contentarea '>
              <Zoom>  <h4 >PlayAll Sports Arena</h4>
                <h2 >Sri Venkateshwar International School, Sec 18 Dwarka</h2>
                <p>With a 6-Vs-6 International standard 13 & 55 mm  <b>Cricket Turf </b>, & 1 hight quality Pickleball Court, PlayAll is one of the best mini <b>Cricket Ground</b> in Dwarka. With Amenities like Ample Parking, Shower, Cafeteria, and Sports Shop, we ensure that your Cricket experience is hassel free and awesome. </p>
              </Zoom>
              <span class='address'>
                Address - Sri Venkateshwar International School, Sector 18, Dwarka, New Delhi - 110075| Contact - 011-411-69588
              </span>

              <div class='btarea mb-3'>
                <a
                  href='https://maps.app.goo.gl/55pSEX3vonYtVwCs6'
                  class='btwhite1'
                >

                  <svg width='34' height='41'>
                    <use href='#googleMap'></use>
                  </svg>
                  <Fade left> Directions</Fade>
                </a>
              </div>

              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  href='https://play.google.com/store/apps/details?id=com.playallrn'
                  class='btorng'
                  style={{ background: "#000000" }}
                  target="_blank"
                >
                  {" "}
                  <img alt='' src='images/playStore.png' style={{ height: "120px" }} />
                </a>


                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://apps.apple.com/us/app/play-all/id6480510323'
                  style={{ background: "#000000" }}

                  target="_blank"
                >
                  <img src='images/appStore.png' alt='' style={{ height: "120px" }} />
                </a>
              </div>


            </div>
          </div>
        </div>
      </section>

      <section class='partnersec cricketdeco'>
        <div class='container'>
          <div class='innerbx pb-5'>
            <div class='contentarea '>
              <Zoom>  <h4 >PlayAll</h4>
                <h2 >Greater Noida</h2>
                <p>With a 5-Vs-5 International standard 13 mm <b>Cricket Turf </b>, Play All is one of the best mini <b>Cricket Ground</b> in Greater Noida. With Amenities like Ample Parking, Cafeteria, and Sports Shop, we ensure that your Cricket experience is hassel free and awesome. </p>
              </Zoom>
              <span class='address'>
                Address - Gaur City Sports Complex, E Block, Gaur City 1, Sector 4, Greater Noida, Uttar Pradesh 201009| Contact - 011-411-87822
              </span>

              <div class='btarea mb-3'>
                <a
                  href='https://goo.gl/maps/XRjs1h6FZjYUoPtv7'
                  class='btwhite1'
                >

                  <svg width='34' height='41'>
                    <use href='#googleMap'></use>
                  </svg>
                  <Fade left> Directions</Fade>
                </a>
              </div>

              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  href='https://play.google.com/store/apps/details?id=com.playallrn'
                  class='btorng'
                  style={{ background: "#000000" }}
                  target="_blank"
                >
                  {" "}
                  <img alt='' src='images/playStore.png' style={{ height: "120px" }} />
                </a>


                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://apps.apple.com/us/app/play-all/id6480510323'
                  style={{ background: "#000000" }}

                  target="_blank"
                >
                  <img src='images/appStore.png' alt='' style={{ height: "120px" }} />
                </a>
              </div>


            </div>
            <div class=' rightbx startplayarea1 d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade >
                  <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1400} swipeable={true} emulateTouch={true}>

                    <img src='images/gallery/gaurs_box-cricket_0.png' alt='cricket pitch near me' />
                    <img src='images/gallery/gaurs_box-cricket_1.png' alt='box-cricket turf' />
                    <img src='images/gallery/gaurs_box-cricket_2.png' alt='cricket ground near me' />
                  </Carousel></Fade>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class='partnersec '>
        <div className='container'>
          <div class='innerbx pb-5'>
            <div class=' rightbx startplayarea d-flex justify-content-center'>
              <div class='startplaybox'>

                <Fade >
                  <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1400} swipeable={true} emulateTouch={true}>

                    <img src='images/gallery/noida_box_cricket_0.png' alt='cricket turf' />
                    <img src='images/gallery/noida_box_cricket_1.png' alt='cricket pitch near me' />
                    <img src='images/gallery/noida_box_cricket_2.png' alt='box-cricket turf' />
                    <img src='images/gallery/noida_box_cricket_3.png' alt='cricket ground near me' />
                  </Carousel></Fade>

              </div>
            </div>
            <div class='contentarea '>
              <Zoom><h4 >PlayAll </h4>
                <h2 >Noida </h2>
                <p>With a 5-Vs-5 International standard 13 mm <b>Cricket Turf </b>, Play All is one of the best<b> Box Cricket Ground</b> in Noida. With Amenities like Ample Parking, Cafeteria, and Online Booking, we ensure that your Cricket experience is hassel free and awesome. </p>

              </Zoom>
              <span class='address'>
                Near Noida Pet Clinic, Sector 73, Noida, Uttar Pradesh 201301 | Contact - 01140849901
              </span>
              <div class='btarea mb-3'>


                <a href='https://goo.gl/maps/MHyikEmmcw22' class='btwhite1'
                  target="_blank">

                  <svg width='34' height='41'>
                    <use href='#googleMap'></use>
                  </svg>
                  <Zoom >Directions</Zoom>
                </a>
              </div>


              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  href='https://play.google.com/store/apps/details?id=com.playallrn'
                  class='btorng'
                  style={{ background: "#000000" }}
                  target="_blank"
                >
                  {" "}
                  <img alt='' src='images/playStore.png' style={{ height: "120px" }} />
                </a>


                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://apps.apple.com/us/app/play-all/id6480510323'
                  style={{ background: "#000000" }}

                  target="_blank"
                >
                  <img src='images/appStore.png' alt='' style={{ height: "120px" }} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class='partnersec '>
        <div class='container'>
          <div class='innerbx pb-5'>
            <div class='contentarea '>
              <Zoom>  <h4 >PlayAll</h4>
                <h2 >Faridabad</h2>
                <p>With a 5-Vs-5 International standard 13 mm <b>Cricket Turf </b>, Play All is one of the best mini <b>Cricket Ground</b> in Faridabad. With Amenities like Ample Parking, Shower, Cafeteria, and Sports Shop, we ensure that your Cricket experience is hassel free and awesome. </p>
              </Zoom>
              <span class='address'>
                Address - Near Sai Mandir, Sector 86, Faridabad, Haryana 121002| Contact - 01141187780
              </span>

              <div class='btarea mb-3'>
                <a
                  href='https://g.page/play-all-sports-faridabad?share'
                  class='btwhite1'
                >

                  <svg width='34' height='41'>
                    <use href='#googleMap'></use>
                  </svg>
                  <Fade left> Directions</Fade>
                </a>
              </div>

              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  href='https://play.google.com/store/apps/details?id=com.playallrn'
                  class='btorng'
                  style={{ background: "#000000" }}
                  target="_blank"
                >
                  {" "}
                  <img alt='' src='images/playStore.png' style={{ height: "120px" }} />
                </a>


                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://apps.apple.com/us/app/play-all/id6480510323'
                  style={{ background: "#000000" }}

                  target="_blank"
                >
                  <img src='images/appStore.png' alt='' style={{ height: "120px" }} />
                </a>
              </div>


            </div>
            <div class=' rightbx startplayarea1 d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade >
                  <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1400} swipeable={true} emulateTouch={true}>

                    <img src='images/gallery/faridabad_box_cricket_0.png' alt='cricket turf' />
                    <img src='images/gallery/faridabad_box_cricket_1.png' alt='cricket pitch near me' />
                    <img src='images/gallery/faridabad_box_cricket_2.png' alt='box-cricket turf' />
                    <img src='images/gallery/faridabad_box_cricket_3.png' alt='cricket ground near me' />
                    <img src='images/gallery/faridabad_box_cricket_4.png' alt='cricket ground near me' />
                  </Carousel></Fade>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class='partnersec cricketdeco'>
        <div className='container'>
          <div class='innerbx pb-5'>
            <div class=' rightbx startplayarea d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade >
                  <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1400} swipeable={true} emulateTouch={true}>
                    <img src='images/gallery/gurgaon_box_cricket_0.png' alt='gully cricket ground' />
                    <img src='images/gallery/gurgaon_box_cricket_1.png' alt='box cricket ground' />
                    <img src='images/gallery/gurgaon_box_cricket_2.png' alt='box cricket turf' />
                    <img src='images/gallery/gurgaon_box_cricket_3.png' alt='cricket ground gurgaon' />
                    <img src='images/gallery/gurgaon_box_cricket_5.png' alt='box cricket gurgaon' />
                  </Carousel>
                </Fade>
              </div>
            </div>
            <div class='contentarea '>
              <Zoom>
                <h4 > PlayAll</h4>
                <h2 >Gurgaon</h2>
                <p>With a 5-Vs-5 International standard 13 mm <b>Cricket Turf </b>, Play All is one of the best<b> Box Cricket Ground</b> in Gurgaon. With Amenities like Ample Parking, Shower, Cafeteria, and Sports Shop, we ensure that your Cricket experience is hassel free and awesome. </p>
              </Zoom>
              <span class='address'>
                Address - CRPF Road, Opposite Heritage School, Sector 62, Gurgaon | Contact - 01141179906
              </span>
              <div class='btarea mb-3'>
                <a href='https://goo.gl/maps/ehmfU6Qtyw82  ' class='btwhite1'
                  target="_blank">
                  <svg width='34' height='41'>
                    <use href='#googleMap'></use>
                  </svg>
                  <Zoom >Directions</Zoom>
                </a>
              </div>
              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  href='https://play.google.com/store/apps/details?id=com.playallrn'
                  class='btorng'
                  style={{ background: "#000000" }}
                  target="_blank"
                >
                  {" "}
                  <img alt='' src='images/playStore.png' style={{ height: "120px" }} />
                </a>


                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://apps.apple.com/us/app/play-all/id6480510323'
                  style={{ background: "#000000" }}

                  target="_blank"
                >
                  <img src='images/appStore.png' alt='' style={{ height: "120px" }} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class='partnersec '>
        <div class='container'>
          <div class='innerbx pb-5'>
            <div class='contentarea '>
              <Zoom>  <h4 >PlayAll Box-Cricket Arena</h4>
                <h2 >New Green Field School, Alaknanda Delhi</h2>
                <p>With a 7-Vs-7 International standard 13 & 50 mm <b> Turf </b>, PlayAll is one of the best mini <b>Cricket Ground</b> in South Delhi. With Amenities like Ample Parking and online bookings, we ensure that your Cricket experience is hassel free and awesome. </p>
              </Zoom>
              <span class='address'>
                Address - New Green Field School, Alaknanda, New Delhi, Delhi| Contact - 011-411-87496
              </span>

              <div class='btarea mb-3'>
                <a
                  href='https://maps.app.goo.gl/a9iKRNwGpwH3xgreA'
                  class='btwhite1'
                >

                  <svg width='34' height='41'>
                    <use href='#googleMap'></use>
                  </svg>
                  <Fade left> Directions</Fade>
                </a>
              </div>

              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  href='https://play.google.com/store/apps/details?id=com.playallrn'
                  class='btorng'
                  style={{ background: "#000000" }}
                  target="_blank"
                >
                  {" "}
                  <img alt='' src='images/playStore.png' style={{ height: "120px" }} />
                </a>


                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://apps.apple.com/us/app/play-all/id6480510323'
                  style={{ background: "#000000" }}

                  target="_blank"
                >
                  <img src='images/appStore.png' alt='' style={{ height: "120px" }} />
                </a>
              </div>


            </div>
            <div class=' rightbx startplayarea1 d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade >
                  <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1400} swipeable={true} emulateTouch={true}>
                    <img src='images/gallery/NGF_box_cricket_1.png' alt='cricket turf' />
                    <img src='images/gallery/NGF_box_cricket_2.png' alt='box-cricket turf' />
                    <img src='images/gallery/NGF_box_cricket_3.png' alt='cricket ground near me' />
                  </Carousel></Fade>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class='partnersec cricketdeco'>
        <div className='container'>
          <div class='innerbx pb-5'>
            <div class=' rightbx startplayarea d-flex justify-content-center'>
              <div class='startplaybox'>

                <Fade >
                  <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1400} swipeable={true} emulateTouch={true}>

                    <img src='images/gallery/orbitMall_box_cricket_1.png' alt='box cricket ground' />
                    <img src='images/gallery/orbitMall_box_cricket_2.png' alt='box cricket turf' />
                    <img src='images/gallery/orbitMall_box_cricket_3.png' alt='cricket ground jaipur' />
                  </Carousel></Fade>

              </div>
            </div>
            <div class='contentarea '>
              <Zoom><h4 >PlayAll </h4>
                <h2 >Orbit Mall - Jaipur </h2>
                <p>With a 5-Vs-5 International standard 13 mm <b>Cricket Turf </b>, Play All is one of the best<b> Box Cricket Ground</b> in Jaipur. With Amenities like Ample Parking and Online Booking, we ensure that your Cricket experience is hassel free and awesome. </p>

              </Zoom>
              <span class='address'>
                Address - Civil Lines Metro Station, Orbit Mall, Ajmer Rd, Madrampur, Civil Lines, Jaipur, Rajasthan 302006 | Contact - 	011-411-86654
              </span>
              <div class='btarea mb-3'>


                <a href='https://goo.gl/maps/4VAo7j8ZzX9rBojQ9' class='btwhite1'
                  target="_blank">

                  <svg width='34' height='41'>
                    <use href='#googleMap'></use>
                  </svg>
                  <Zoom >Directions</Zoom>
                </a>
              </div>

              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  href='https://play.google.com/store/apps/details?id=com.playallrn'
                  class='btorng'
                  style={{ background: "#000000" }}
                  target="_blank"
                >
                  {" "}
                  <img alt='' src='images/playStore.png' style={{ height: "120px" }} />
                </a>


                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://apps.apple.com/us/app/play-all/id6480510323'
                  style={{ background: "#000000" }}

                  target="_blank"
                >
                  <img src='images/appStore.png' alt='' style={{ height: "120px" }} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class='partnersec '>
        <div class='container'>
          <div class='innerbx pb-5'>
            <div class='contentarea '>
              <Zoom>  <h4 >PlayAll Sports Arena</h4>
                <h2 >City Centre Mall - Raipur</h2>
                <p>With 2 6-Vs-6 International standard 50 mm <b> Turf </b>, PlayAll is one of the best mini <b>Cricket Ground</b> in Chhattisgarh. With Amenities like Ample Parking and online bookings, we ensure that your Cricket experience is hassel free and awesome. </p>
              </Zoom>
              <span class='address'>
                Address - Roof Top, City Centre Mall, IGVP, Pandri, Raipur, Chhattisgarh 492001| Contact - 01141170822
              </span>

              <div class='btarea mb-3'>
                <a
                  href='https://goo.gl/maps/v5wyAwswt5UQK1y19?coh=178572&entry=tt'
                  class='btwhite1'
                >

                  <svg width='34' height='41'>
                    <use href='#googleMap'></use>
                  </svg>
                  <Fade left> Directions</Fade>
                </a>
              </div>

              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  href='https://play.google.com/store/apps/details?id=com.playallrn'
                  class='btorng'
                  style={{ background: "#000000" }}
                  target="_blank"
                >
                  {" "}
                  <img alt='' src='images/playStore.png' style={{ height: "120px" }} />
                </a>


                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://apps.apple.com/us/app/play-all/id6480510323'
                  style={{ background: "#000000" }}

                  target="_blank"
                >
                  <img src='images/appStore.png' alt='' style={{ height: "120px" }} />
                </a>
              </div>


            </div>
            <div class=' rightbx startplayarea1 d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade >
                  <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1400} swipeable={true} emulateTouch={true}>


                    <img src='images/gallery/raipur_city_center_mall_1.png' alt='box cricket pitch near me' />
                    <img src='images/gallery/raipur_city_center_mall_2.png' alt='best turf in raipur' />
                    <img src='images/gallery/raipur_city_center_mall_3.png' alt='cricket ground in raipur' />
                    <img src='images/gallery/raipur_city_center_mall_4.png' alt='box cricket turf near me' />
                  </Carousel></Fade>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class='partnersec '>
        <div className='container'>
          <div class='innerbx pb-5'>
            <div class=' rightbx startplayarea d-flex justify-content-center'>
              <div class='startplaybox'>

                <Fade >
                  <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1400} swipeable={true} emulateTouch={true}>

                    <img src='images/gallery/noida_105_1.png' alt='cricket turf' />
                    <img src='images/gallery/noida_105_2.png' alt='box-cricket turf' />
                    <img src='images/gallery/noida_105_3.png' alt='cricket ground near me' />
                  </Carousel></Fade>

              </div>
            </div>

            <div class='contentarea '>
              <Zoom>  <h4 >PlayAll Fortune World School</h4>
                <h2 >Noida Sec 105</h2>
                <p>With a 6-Vs-6 International standard 13 & 55 mm  <b>Cricket Turf </b>, Play All is one of the best mini <b>Cricket Ground</b> in Noida. With Amenities like Ample Parking, Shower, Cafeteria, and Sports Shop, we ensure that your Cricket experience is hassel free and awesome. </p>
              </Zoom>
              <span class='address'>
                Address - Fortune World School, Block B, Sector 105, Noida, Uttar Pradesh 201304| Contact - 011-411-69966
              </span>

              <div class='btarea mb-3'>
                <a
                  href='https://g.co/kgs/yTecqg'
                  class='btwhite1'
                >

                  <svg width='34' height='41'>
                    <use href='#googleMap'></use>
                  </svg>
                  <Fade left> Directions</Fade>
                </a>
              </div>

              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  href='https://play.google.com/store/apps/details?id=com.playallrn'
                  class='btorng'
                  style={{ background: "#000000" }}
                  target="_blank"
                >
                  {" "}
                  <img alt='' src='images/playStore.png' style={{ height: "120px" }} />
                </a>


                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://apps.apple.com/us/app/play-all/id6480510323'
                  style={{ background: "#000000" }}

                  target="_blank"
                >
                  <img src='images/appStore.png' alt='' style={{ height: "120px" }} />
                </a>
              </div>


            </div>
          </div>
        </div>
      </section>


      <section class=' techsection footballdeco'>
        <div class='container'>
          <div class='headarea'>
            <h4 class=' sectiontitle '>
              Play All
            </h4>
            <Fade bottom cascade><h2 class=' sectionhead '>
              One-Stop-Destination for Cricket Lovers
            </h2></Fade>
          </div>
          <div class='techarea sportsarea'>
            <div class='sportsbox '>
              <div class='iconarea'>
                <svg viewBox="0 0 55.558 47.814">
                  <use href="#data"></use>
                </svg>
              </div>
              <Zoom><h4 >Pay & Play </h4></Zoom>
              <span>BOOK YOUR TURF IN ADVANCE, SIT BACK & RELAX!</span>
            </div>
            <div class='sportsbox '>
              <div class='iconarea'>
                <svg>
                  <use href='#football '></use>
                </svg>
              </div>
              <Zoom><h4 >Good old days</h4></Zoom>
              <span>
                COME WITH YOUR FRIENDS AND RELIVE YOUR CHILDHOOD DAYS

              </span>
            </div>
            <div class='sportsbox '>
              <div class='iconarea'>
                <svg viewBox='0 0 55.558 47.814'>
                  <use href='#stadium'></use>
                </svg>
              </div>
              <Zoom><h4 >Best-in-class Infra</h4></Zoom>
              <span>
                INTERNATIONAL TURF, LED LIGHTING, 20 FT HEIGHT
              </span>
            </div>

          </div>
        </div>
      </section>


    </main>
  </>
);

const BoxCricket = () => {
  return (
    <>

      <Helmet>
        <title>Box-Cricket - Gully Cricket, Cricket Turf, and Cricket Ground</title>
        <meta name="description" content="Play all offers best Cricket Turf and Cricket Ground. In Noida we have 1 cricket ground near sector 73. In Faridabad we have 1 cricket ground near sector 86. In Gurgoan we have 1 cricket ground near sector 62." />
      </Helmet>
      <div style={{ overflowY: "scroll", height: "calc(100vh)" }}>
        {topSectionData}
        <div class='headbanner eventbg' data-aos='fade'>
          <div class='container '>
            <NavSection current={"cricket-ground"} />
            {contentSection}
          </div>
        </div>
        {mainSection}
        {footerSection}
      </div>
    </>
  );
};

export default BoxCricket;
