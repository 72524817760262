import React from "react";
import { topSectionData, footerSection, NavSection } from "./HeaderAndFooter";
import { Helmet } from "react-helmet";
import { Fade, Zoom, } from 'react-reveal';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const contentSection = (
  <>
    <div class='dispfbanner'>
      <div class='bannerarea'>
        <h4 data-aos='zoom-in-down'>Swim in your Favorite swimming pool</h4>
        <h1 data-aos='fade-up'>
          We Are Redefining Sports. Experience The Difference
        </h1>
        <div class='btarea' data-aos='zoom-in'></div>
      </div>
    </div>
    <div class='aboutarea ' data-aos='zoom-in'>
      <div class='aboutbox '>
        <div class='boxes '>
          <svg>
            <use href='#park'></use>
          </svg>
          <h5> Online Booking available</h5>
        </div>
        <div class='boxes '>
          <svg viewBox='0 0 55.558 47.814'>
            <use href='#stadium'></use>
          </svg>
          <h5> Best in Class Infra</h5>
        </div>
        <div class='boxes '>
          <svg>
            <use href='#football'></use>
          </svg>
          <h5> Semi-Olympic Pool </h5>
        </div>
      </div>
    </div>
  </>
);

const mainSection = (
  <>
    <main>

    <section class='partnersec '>
        <div class='container'>
          <div class='innerbx pb-5'>
            <div class='contentarea '>
              <Zoom>  <h4 >PlayAll</h4>
                <h2 >Swimming Pool - Sector 17 Faridabad</h2>
                <p>With a semi-olympic size <b>Swimming Pool </b>, PlayAll is one of the best swimming academy in Faridabad. With Amenities like Ample Parking, Cafeteria, and Sports Shop, we ensure that your Swimming experience is hassel free and awesome. </p>
              </Zoom>
              <span class='address'>
                Address - PlayAll Swimming Pool - Modern School, Sector 17, Faridabad, Haryana 121002| Contact - 011-411-71906
              </span>

              <div class='btarea mb-3'>
                <a
                  href='https://goo.gl/maps/DVoMniRChne2cJEM7'
                  class='btwhite1'
                >

                  <svg width='34' height='41'>
                    <use href='#googleMap'></use>
                  </svg>
                  <Fade left> Directions</Fade>
                </a>
              </div>

              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  // href='https://playo.co/venues/greater-noida-west-ncr/play-all-sports-@-gaur-city-sports-complex-greater-noida-west-ncr'
                  class='btorng'

                  target="_blank"
                >
                  {" "}
                  <img alt='' src='images/playo_logo.png' />
                </a>


                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  // href='https://hudle.in/venues/play-all-sports-complex-gaur-city/180250'

                  target="_blank"
                >
                  <img src='images/huddle.svg' alt='' />
                </a>
                {/* <div class='divider'></div>
                <a
                  href='http://book.sportido.com/landing/Faridabad,_Haryana,_India/Box_Cricket/Play.All_Sports_Complex_Faridabad/3491_2584'
                  class='btorng'

                  target="_blank"
                >
                  {" "}
                  <img alt='' src='images/sportido.png' />
                </a> */}
              </div>


            </div>
            <div class=' rightbx startplayarea1 d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade >
                  <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1400} swipeable={true} emulateTouch={true}>

                    <img src='images/gallery/swimmingPoolSector17Faridabad.png' alt='swiming pool near me' />
                    {/* <img src='images/gallery/gaurs_box-cricket_1.png' alt='box-cricket turf' />
                    <img src='images/gallery/gaurs_box-cricket_2.png' alt='cricket ground near me' /> */}
                  </Carousel></Fade>
              </div>
            </div>
          </div>
        </div>
      </section>




    </main>
  </>
);

const Swimming = () => {
  return (
    <>

      <Helmet>
        <title>Swimming Pool - Swimming Academy</title>
        <meta name="description" content="Play all offers best Swimming Pool and Swimming Academy in Faridabad" />
      </Helmet>
      <div style={{ overflowY: "scroll", height: "calc(100vh)" }}>
        {topSectionData}
        <div class='headbanner swimmingBg' data-aos='fade'>
          <div class='container '>
            <NavSection current={"swimming"} />
            {contentSection}
          </div>
        </div>
        {mainSection}
        {footerSection}
      </div>
    </>
  );
};

export default Swimming;
